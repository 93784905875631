import axios from "axios";
import { apiUrl } from "../utils/axios";

export const login = (username, password) => {
    return axios
        .post(`${apiUrl}login`, {username, password})
        .then((response) => {
            console.log(response.data);
            if (response.data.RESPONSE_CODE ===200) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("logedIn", true);
            }
            return response.data;
        });
};

export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("logedIn");
    localStorage.clear();
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};


