
export const baseLayers = [
  {
    name: "OSM",
    urlTemplate: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
    options: {
      attribution: "© OpenStreetMap contributors",
      maxZoom: 19,
    },
  },
  {
    name: "OSM-Black-and-White",
    urlTemplate:
      "http://{s}.www.toolserver.org/tiles/bw-mapnik/{z}/{x}/{y}.png",
    options: {
      attribution:
        // eslint-disable-next-line
        "&copy; " + "<a href='http://www.openstreetmap.org'>Open StreetMap</a>",
      maxZoom: 18,
    },
  },
  {
    name: "OSM-Humanitaire",
    urlTemplate: "http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    options: {
      attribution:
        // eslint-disable-next-line
        "© OpenStreetMap Contributors. Tiles courtesy of Humanitarian OpenStreetMap Team",
      maxZoom: 20,
    },
  },
];

export const countyPositions = [
  { name: "Pays", latLng: [8.22, 1.13], zoom: 7 },
  { name: "Aneho", latLng: [6.23, 1.59], zoom: 14 },
  { name: "Blitta", latLng: [8.31, 0.99], zoom: 13 },
  { name: "Pya", latLng: [9.65, 1.16], zoom: 13 },
  { name: "Nioukpourma", latLng: [10.84, 0.14], zoom: 13 },
  { name: "Hihéatro", latLng: [7.535, 1.1], zoom: 13 },
];

export const projectStepsSections = [
  {
    name: "Préparation",
    stepLines: [
      { stepNumber: 1, done: true, deadline: "01/10/2021" },
      { stepNumber: 2, done: false, deadline: "01/10/2021" },
      { stepNumber: 3, done: true, deadline: "01/10/2021" },
      { stepNumber: 4, done: false, deadline: "01/10/2021" },
    ],
  },
  {
    name: "Opération",
    stepLines: [
      { stepNumber: 1, done: true, deadline: "01/10/2021" },
      { stepNumber: 2, done: false, deadline: "01/10/2021" },
    ],
  },
  {
    name: "Technologie",
    stepLines: [
      { stepNumber: 1, done: true, deadline: "01/10/2021" },
      { stepNumber: 2, done: false, deadline: "01/10/2021" },
    ],
  },
  {
    name: "Rendu final",
    stepLines: [
      { stepNumber: 1, done: true, deadline: "01/10/2021" },
      { stepNumber: 2, done: false, deadline: "01/10/2021" },
    ],
  },
];

export const regionsList = [
  {
    regionId: 1,
    regionName: 'Maritime',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 100,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 21,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 80,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 4,
        sectorPercent: 11,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 2,
    regionName: 'Plateaux',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 42,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 15,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 22,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 4,
        sectorPercent: 0,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 3,
    regionName: 'Centrale',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 40,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 64,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 33,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 4,
        sectorPercent: 0,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 4,
    regionName: 'Kara',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 0,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 5,
    regionName: 'Savanes',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 0,
        sectorName: 'secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'secteur 3',
      },
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 6,
    regionName: 'Grand Lomé',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 0,
        sectorName: 'secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'secteur 3',
      },
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  }
]

export const sectorsList = [
  {
    sectorId: 1,
    sectorName: 'Secteur 1',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 2,
    sectorName: 'Secteur 2',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 3',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 4',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 5',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 6',
    sectorStatus: 'En cours'
  }
]

export const accessibility = [
  {
    month: 'Octobre',
    status: 'En cours'
  },
  {
    month: 'novembre',
    status: 'Terminé'
  },
  {
    month: 'Décembre',
    status: 'En cours'
  },
  {
    month: 'Janvier',
    status: 'En cours'
  },
  {
    month: 'Février',
    status: 'En cours'
  },
  {
    month: 'Mars',
    status: 'En cours'
  }
]
