import axios from 'axios';
import { apiUrl } from '../utils/axios';


export const sectorsList = async () =>{
	const {data} = await axios.get(`${apiUrl}secteurs`);
	for(var i = 0; i < data.length; i++){
	    data[i].sectorPercent = 60;
	}
	//console.log(data);


	return data;
};
