import React from 'react';
import './Project.scss';
import Header from "../../page_sections/header/Header";
import Footer from "../../page_sections/footer/Footer";
import togo from "../../../assets/images/Togo-logo.png";

const Project = () => {
    return (
        <div>
            <Header />
            <div className="project-container">
                <div className="project-container-inner">
                    <div className="project-container-inner-header">
                        <img src={togo} alt="logo"/>
                    </div>
                    <div className="project-container-inner-body">
                        <h1>Programme de recencement des infrastructures sociales et économiques </h1>
                        <p>
                            <h3>L’administration territoriale augmentée par la donnée</h3>
et la cartographie
La plateforme des infrastructures socio-économiques permet au grand public et aux collaborateurs de la République Togolaise l’accès aux données les plus récentes et précises concernant l’état actuel des infrastructures socio-économiques dans la République Togolaise.
Ces données sont actualisées et analysées pour permettre une planification et une optimisation des réseaux d’infrastructures.
                        </p>

                        <p>
                            <h3>Contexte</h3>
                            L’opérationnalisation des projets prioritaires de la Feuille de route 2020-2025 du gouvernement nécessite un déploiement optimal et efficient d’infrastructures socio-économiques dans les secteurs clés de développement notamment l'éducation, la santé, l’administration territoriale, les transports, mines et énergie, l'eau, les télécommunications, l'agriculture, l'environnement.
    Dans ce cadre-là, le Ministère de l’Administration Territoriale, de la Décentralisation et du Développement des Territoires (MATDDT) initie un projet de recensement national et de cartographie des infrastructures dans les secteurs prioritaires susmentionnés.
                            </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Project;
