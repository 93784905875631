import React from "react";
import './Accessibility.scss';
/*import CheckboxBlankIcon from "../../../assets/images/checkbox-blank-icon.png";
import CheckboxFilledIcon from "../../../assets/images/checkbox-filled-icon.png";*/

const Accessibility = ({ text, status, color }) => {
    return (
        <div className="accessibility-content">
            <div className="accessibility-content-left">
                    <p>{text}</p>
            </div>
            <div className="accessibility-content-right">
                <div className="accessibility-content-right-status" style={color}>
                    <span>{status}</span>
                </div>
            </div>
        </div>
    )
};

export default Accessibility;
