import React, {useEffect, useState} from 'react';
import './progress-bar.scss';

const ProgressBarMobile = ({ percent, sectorName }) => {

    const nbr = 10;
    //const [percentage, setPercentage] = useState(0);
    const [percentageList, setPercentageList] = useState([]);

    useEffect(() => {
        if (percent > 0) {
            let perRound = Math.round(percent / nbr);
            let percentageList = [];
            //setPercentage(perRound);
            for(let i = 0; i < perRound; i++) {
                percentageList.push(<span key={i}></span>);
            }
            setPercentageList(percentageList);
        } else {
            /*setPercentage(0);*/
        }
    }, [percent]);

    const style = {
        width: `calc(${nbr}% - 4px)`,
    }

    return (
        <div className="progress-bar mobile">
            <div className="progress-bar-inner">
                <div className="progress-bar-inner-title">
                    <h5>{sectorName}</h5>
                </div>
                <div className={"progress-bar-inner-block " + (percent > 0 ? 'start' : '')}>
                    {
                        percentageList && percentageList.map((i) => {
                            return (
                                    <span style={style} key={i}></span>
                            )
                        })
                    }
                </div>
                <div className="progress-bar-inner-text">
                    <p>{percent}%</p>
                </div>
            </div>
        </div>
    )
}

export default ProgressBarMobile;
