//import React, { useState } from "react";
import "./app.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home/home";
import Login from "./login/login";
import MitsioMotu from "./about/mitsio-motu/MitsioMotu";
import Project from "./about/project/Project";
import NotFound from "./not-found/notFound";
import ProtectedRoute from "../utils/ProtectedRoute";
//import authHeader from "../services/authentication-header";

const App = () => {
  /*const [theme, setTheme] = useState('blue');

    // The function that toggles between themes
    const toggleTheme = () => {
        // if the theme is not blue, then set it to green
        if (theme === 'green') {
            setTheme('blue');
            // otherwise, it should be blue
        } else {
            setTheme('green');
        }
    }*/

  return (
    /*<div className={"page-wrapper " + (theme === 'blue' ? 'blue' : 'green')}>*/
    <div className="page-wrapper green">
      {/*<div className="page-wrapper-button">
                <button onClick={toggleTheme}>Changer de couleur</button>
            </div>*/}
      <Router>
        <Switch>
          {/* Login page */}
          <Route exact path="/">
            <Login />
          </Route>
          {/* Home page */}
          <ProtectedRoute exact path="/tableau-de-bord" component={Home} />
          {/* About Project */}
          <ProtectedRoute exact path="/about-project" component={Project} />
          {/* About Mitsio Motu */}
          <ProtectedRoute
            exact
            path="/about-mitsio-motu"
            component={MitsioMotu}
          />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
